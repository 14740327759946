import { FunctionComponent } from 'react'

import {
  TGoalsStaffListItem,
  TGoalsLeaderDetails,
  TGoal,
  TGoalAdjustmentsItem,
  TGoalsItem
} from 'integration/resources/goals'
import { RootStackScreenComponentProps } from 'src/navigation'

export type GoalsLeaderDetailsScreenComponent = FunctionComponent<GoalsLeaderDetailsScreenProps>

export type GoalsLeaderDetailsScreenProps = RootStackScreenComponentProps<'GoalsLeaderDetails'>

export type ResponsiveGoalsLeaderDetailsScreenComponent = FunctionComponent<
  ReturnType<UseGoalsLeaderDetailsScreen>
>

export enum Lists {
  Production,
  Insurance,
  InsuranceAuto,
  Webmotors,
  MoreAccounts,
  Charge,
  ActiveBase
}

export type ListButtonProps = {
  list: Lists
  label: string
  currentList: Lists
  onPress: (list: Lists) => void
}

export type SearchBarProps = {
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  placeholder: string
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type StaffListProps = {
  search?: string
  staffListData?: TGoalsStaffListItem[]
  staffListIsLoading: boolean
  staffListIsFetchingNextPage: boolean
  goalsIsOpen: boolean
  handleOpenGoalAdjustment(staffCode: number): void
}

export type StaffCardProps = TGoalsStaffListItem & {
  handleOpenGoalAdjustment(staffCode: number): void
}

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear(): void
  handleApply(): void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen(): void
  orderByOnClose(): void
}

export type DropdownGoalsProps = {
  goals?: TGoal[]
  goalsListIsOpen: boolean
  goalsListOnOpen(): void
  goalsListOnClose(): void
  handleSelectNewGoal(goal: TGoal): void
  selectedGoal?: TGoal
  isCurrency: boolean
}

export type GoalAdjustmentModalProps = {
  goalAdjustmentModalIsOpen: boolean
  handleCloseGoalAdjustment(): void
  goalsListIsOpen: boolean
  goalsListOnOpen: () => void
  goalsListOnClose: () => void
  goals: TGoal[]
  anyHookIsLoading: boolean
  handleUpdateAdjustedGoals: (
    selectedGoal: TGoal,
    adjustedGoalValue: number,
    adjustedPercentage: number,
    value: number
  ) => void
  saveAdjustments: () => void
  totalAdjustedGoals: number
  savedAdjustments?: TGoalAdjustmentsItem
}

export type UseGoalsLeaderDetailsScreen = ({
  navigation,
  route
}: Pick<GoalsLeaderDetailsScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  isAdmin: boolean
  handleGoBack: () => void
  detailsData?: TGoalsLeaderDetails
  leaderDetailsIsLoading: boolean
  goalsIsOpen: boolean
  currentList: Lists
  handleListChange: (list: Lists) => void
  staffListData?: TGoalsStaffListItem[]
  staffListIsLoading: boolean
  staffListIsFetchingNextPage: boolean
  staffListTotalItems: number
  onEndReached: () => void
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
  handleGoToOpenGoalsPeriod: () => void
  reactivateModalIsOpen: boolean
  reactivateModalOnClose(): void
  reactivateModalOnOpen(): void
  handleReactivate: () => void
  reactivateIsLoading: boolean
  anyHookIsLoading: boolean
  handleDownloadReport: () => Promise<void>
  downloadReportIsLoading: boolean
  concludeModalIsOpen: boolean
  concludeModalOnClose(): void
  concludeModalOnOpen(): void
  handleConclude: () => void
  concludeIsLoading: boolean
  goalAdjustmentModalIsOpen: boolean
  handleOpenGoalAdjustment(staffCode: number): void
  handleCloseGoalAdjustment(): void
  goalsListIsOpen: boolean
  goalsListOnOpen: () => void
  goalsListOnClose: () => void
  goalsAdjustments?: TGoalsItem
  handleUpdateAdjustedGoals: (
    selectedGoal: TGoal,
    adjustedGoalValue: number,
    adjustedPercentage: number,
    value: number
  ) => void

  totalAdjustedGoals: number
  confirmAdjustmentsModalIsOpen: boolean
  confirmAdjustmentsModalOnClose(): void
  confirmAdjustmentsModalOnOpen(): void
  handleConcludeAdjustments(): void
  savedAdjustments?: TGoalAdjustmentsItem
}
