export type TParams = {
  leader_std_code?: number
  staff_std_code?: number
  page?: number
  per_page?: number
  order_by?: string
  order_by_direction?: string
  search?: string
  enabled?: boolean
  assigned_stores?: boolean
  goal_status?: GoalsStatus
  goals_adjustments?: TGoalAdjustmentsItem
}

export enum PeriodStatus {
  OPENED = 1,
  CLOSED = 2
}

export enum PeriodType {
  GENERAL = 1,
  LEADER = 2
}

export type TGoalsPeriod = {
  id: string
  year_month: number
  period_type_enum: PeriodType
  status: PeriodStatus
  started_at: string
  finished_at: string
  created_at: string
  updated_at: string
  total_leader_active: string
  goals?: TGoal[]
}

export type TGoalsLeadersListItem = {
  leader_std_code: number
  leader_user_name: string
  profile_image_file_name?: string
  network: {
    network_code?: string
    network_name?: string
  }
  subsidiary: {
    subsidiary_name?: string
    subsidiary_code?: string
  }
  total_specialists: number
  total_goal: number
}

export type TGoalsStaffListItem = {
  user_std_code: number
  user_name: string
  occupation: string
  profile_image_file_name: string
  goal: string
  udpated_goal: string
  udpated_by: string
  last_update: string
  adjusted_percentage?: number
}

export enum GoalsStatus {
  ACTIVED = 1,
  CONCLUDED = 2,
  REACTIVED = 3,
  CLOSED = 4
}

export const GoalsStatusLabels: { [key in GoalsStatus]: string } = {
  [GoalsStatus.ACTIVED]: 'Ativo',
  [GoalsStatus.CLOSED]: 'Fechado',
  [GoalsStatus.REACTIVED]: 'Ativo',
  [GoalsStatus.CONCLUDED]: 'Concluído'
}

export type TGoalsLeaderDetails = {
  started_at: string
  finished_at: string
  leader_std_code: number
  leader_user_name: string
  occupation: string
  profile_image_file_name?: string
  network: {
    network_code: string
    network_name: string
  }
  subsidiary: {
    subsidiary_name: string
    subsidiary_code: string
  }
  status: GoalsStatus
  goals?: TGoal[]
}

export enum BusinessTypes {
  VEHICLES = 1,
  GOODS_AND_SERVICES = 2,
  ALL = 3
}

export type TOpenGoalsPeriodParams = {
  date_start: string
  date_end: string
  leader_std_code?: number
  business?: BusinessTypes
  goals?: {
    production?: number
    insurance?: number
    insurance_auto?: number
    webmotors?: number
    charge?: number
    more_accounts?: number
    active_base?: number
  }
}

export type AdjustmentItem = {
  year_month: number
  sent_goal: number
  adjusted_goal: number
  variance: number
  adjustment_date: string
  adjusted_by: string
}

export type TGoalAdjustment = {
  consultant_name: string
  role: string
  consultant_id: string
  reference: AdjustmentItem
  history: AdjustmentItem
}

export type TAdjustments = {
  type: string
  year_month: number
  goal: number
  adjustments: TGoalAdjustment[]
}

export type TReportHeader = {
  leader_std_code?: number
  leader_name?: string
  current_date?: string
  year_month?: number
  started_at?: string
  finished_at?: string
}

export type TGoalsReport = {
  file_url?: string
  file_name?: string
  header?: TReportHeader
  goal_adjustments?: TAdjustments[]
}

export type TGoal = {
  id: string
  key: string
  title: string
  value: number
  percent?: number
  delta?: number
}

export type TAdjustmentItem = {
  adjusted_value: number
  adjusted_percent: number
  value: number
}

export type TGoalsItem = {
  production: TAdjustmentItem
  insurance: TAdjustmentItem
  insurance_auto: TAdjustmentItem
  webmotors: TAdjustmentItem
  more_accounts: TAdjustmentItem
  charges: TAdjustmentItem
  active_base: TAdjustmentItem
}

export type TGoalAdjustmentsItem = {
  staffInfos: {
    name: string
    role: string
  }
  goals: TGoalsItem
}
