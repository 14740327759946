import {
  TParams,
  TGoalsLeadersListItem,
  TGoalsPeriod,
  PeriodType,
  PeriodStatus,
  TGoalsStaffListItem,
  TGoalsLeaderDetails,
  GoalsStatus,
  TOpenGoalsPeriodParams,
  TGoalsReport,
  TGoalAdjustmentsItem
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

const USE_MOCK = true

const mockAxiosResponse = (data: any) => ({
  data,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
  request: {}
})

export const mockedGoalsLeadersListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 42,
    requestId: 'clujqrnbfba8000919kkjld9d60ef'
  },
  data: Array.from({ length: 10 }, (_, index) => {
    return {
      leader_std_code: 134000 + index,
      leader_user_name: `Líder ${index + 1}`,
      profile_image_file_name: `https://randomuser.me/api/portraits/men/${index}.jpg`,
      network: {
        network_code: `CSS${index}`,
        network_name: `CSS ${
          ['Sudeste', 'Centro Oeste', 'Sul', 'SP Interior', 'SP Capital'][index % 5]
        }`
      },
      subsidiary: {
        subsidiary_name: 'Baixada Santista',
        subsidiary_code: `654${100 + index}`
      },
      total_specialists: Math.floor(Math.random() * 100) + 1,
      total_goal: 164000000
    }
  }),
  status: 200
}

export const mockedGoalsPeriodResponse = (period?: PeriodStatus) => {
  const response = {
    serviceSuccess: true,
    hasErrors: false,
    errors: [],
    metadata: {
      runtime: 27,
      requestId: 'cluwerwjqt0b6000a08jt5ct8ffjl'
    },
    data: {
      id: 'period_001',
      year_month: 202309,
      period_type_enum: PeriodType.GENERAL,
      status: period ?? PeriodStatus.CLOSED,
      started_at: '2024-01-04T15:23:45.293',
      finished_at: '2024-01-04T15:23:45.293',
      createdAt: '2024-01-04T15:23:45.293',
      updatedAt: '2024-01-04T15:23:45.293',
      total_leader_active: '150',
      goals:
        period === PeriodStatus.OPENED
          ? [
              { id: '1', title: 'Produção TABs', value: 5727977.0, percent: 5 },
              { id: '2', title: 'Seguro Prestamista', value: 154140.0, percent: 5 },
              { id: '3', title: '+Contas', value: 104, percent: 5 },
              { id: '4', title: 'Cobranças', value: 416555.0, percent: 5 },
              { id: '5', title: 'Webmotors', value: 40516.0, percent: 5 },
              { id: '6', title: 'Seguro Auto', value: 62244.0, percent: 5 },
              { id: '7', title: 'Base Ativa', value: 107, percent: 10 }
            ]
          : [
              { id: '1', title: 'Produção TABs', value: 5727977.0 },
              { id: '2', title: 'Seguro Prestamista', value: 154140.0 },
              { id: '3', title: '+Contas', value: 104 },
              { id: '4', title: 'Cobranças', value: 416555.0 },
              { id: '5', title: 'Webmotors', value: 40516.0 },
              { id: '6', title: 'Seguro Auto', value: 62244.0 },
              { id: '7', title: 'Base Ativa', value: 107 }
            ]
    },
    status: 200
  }

  return response
}

export const mockedGoalsStaffListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 42,
    requestId: 'clujqrnabfdbds8000919kkjld9d60ef'
  },
  data: Array.from({ length: 10 }, (_, index) => {
    const user_std_code = 134000 + index

    const goalValue = Math.random() * (index + 1) * 100000

    return {
      user_std_code,
      user_name: `Especialista ${index + 1}`,
      occupation: 'Especialista em Vendas',
      profile_image_file_name: `https://randomuser.me/api/portraits/women/${index}.jpg`,
      goal: `${goalValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
      udpated_goal: `${(goalValue * (index + 1) * 1000).toLocaleString('pt-BR', {
        minimumFractionDigits: 2
      })}`,
      udpated_by: `Santander`,
      last_update: '2024-01-04T15:23:45.293',
      adjusted_percentage: index < 4 ? 5 : undefined
    }
  }),
  status: 200
}

export const mockedGoalsLeaderDetailsResponse = ({
  goal_status
}: {
  goal_status?: GoalsStatus
}) => {
  const response = {
    serviceSuccess: true,
    hasErrors: false,
    errors: [],
    metadata: {
      runtime: 33,
      requestId: 'clujrt5x1dfbdf000b1djtkbl0zyfk'
    },
    data: {
      started_at: '2024-01-04T15:23:45.293',
      finished_at: '2024-01-04T15:23:45.293',
      leader_std_code: 134567,
      leader_user_name: 'João da Silva',
      occupation: 'Líder Regional de Vendas',
      profile_image_file_name: 'https://randomuser.me/api/portraits/men/7.jpg',
      network: {
        network_code: 'CSS01',
        network_name: 'CSS Sudeste'
      },
      subsidiary: {
        subsidiary_name: 'Baixada Santista',
        subsidiary_code: '654100'
      },
      status: goal_status ?? GoalsStatus.CLOSED,
      goals: [
        {
          id: '1',
          key: 'production',
          title: 'Produção TABs',
          value: 5727977.0,
          delta: 0,
          percent: 15
        },
        {
          id: '2',
          key: 'insurance',
          title: 'Seguro Prestamista',
          value: 154140.0,
          delta: 0,
          percent: 15
        },
        {
          id: '3',
          key: 'insurance_auto',
          title: 'Seguro Auto',
          value: 62244.0,
          delta: 0,
          percent: 15
        },
        { id: '4', key: 'webmotors', title: 'Webmotors', value: 40516.0, delta: 0, percent: 15 },
        { id: '5', key: 'more_accounts', title: '+Contas', value: 104, delta: 0, percent: 5 },
        { id: '6', key: 'charges', title: 'Cobranças', value: 416555.0, delta: 0, percent: 15 },
        { id: '7', key: 'active_base', title: 'Base Ativa', value: 107, delta: 0, percent: 10 }
      ]
    },
    status: 200
  }

  return response
}

export const mockGoalsReport: TGoalsReport = {
  header: {
    leader_std_code: 12345,
    leader_name: 'João Silva',
    current_date: '2024-11-29T00:00:00.000Z',
    year_month: 202411,
    started_at: '2024-11-01T00:00:00.000Z',
    finished_at: '2024-11-30T23:59:59.000Z'
  },
  goal_adjustments: [
    {
      type: 'Seguro Prestamista',
      year_month: 202411,
      goal: 10000000.0,
      adjustments: [
        {
          consultant_name: 'Carlos Eduardo',
          role: 'Gerente de Vendas',
          consultant_id: '123456',
          reference: {
            year_month: 202411,
            sent_goal: 2000000.0,
            adjusted_goal: 2100000.0,
            variance: 5,
            adjustment_date: '2024-11-10T08:00:00.000Z',
            adjusted_by: 'Ana Clara'
          },
          history: {
            year_month: 202410,
            sent_goal: 1800000.0,
            adjusted_goal: 1890000.0,
            variance: 5,
            adjustment_date: '2024-10-12T08:00:00.000Z',
            adjusted_by: 'Ana Clara'
          }
        },
        {
          consultant_name: 'Paulo Santos',
          role: 'Consultor Comercial',
          consultant_id: '789101',
          reference: {
            year_month: 202411,
            sent_goal: 1500000.0,
            adjusted_goal: 1575000.0,
            variance: 5,
            adjustment_date: '2024-11-15T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          },
          history: {
            year_month: 202410,
            sent_goal: 1400000.0,
            adjusted_goal: 1470000.0,
            variance: 5,
            adjustment_date: '2024-10-16T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          }
        }
      ]
    },
    {
      type: 'Cobranças',
      year_month: 202411,
      goal: 8000000.0,
      adjustments: [
        {
          consultant_name: 'Mariana Almeida',
          role: 'Supervisora Regional',
          consultant_id: '654321',
          reference: {
            year_month: 202411,
            sent_goal: 1500000.0,
            adjusted_goal: 1575000.0,
            variance: 5,
            adjustment_date: '2024-11-15T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          },
          history: {
            year_month: 202410,
            sent_goal: 1400000.0,
            adjusted_goal: 1470000.0,
            variance: 5,
            adjustment_date: '2024-10-16T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          }
        }
      ]
    },
    {
      type: 'Seguro Auto',
      year_month: 202411,
      goal: 12000000.0,
      adjustments: [
        {
          consultant_name: 'João Pereira',
          role: 'Consultor Comercial',
          consultant_id: '789012',
          reference: {
            year_month: 202411,
            sent_goal: 3000000.0,
            adjusted_goal: 3150000.0,
            variance: 5,
            adjustment_date: '2024-11-20T10:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          },
          history: {
            year_month: 202410,
            sent_goal: 2900000.0,
            adjusted_goal: 3045000.0,
            variance: 5,
            adjustment_date: '2024-10-22T10:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          }
        },
        {
          consultant_name: 'Fernanda Costa',
          role: 'Gerente de Contas',
          consultant_id: '654789',
          reference: {
            year_month: 202411,
            sent_goal: 1800000.0,
            adjusted_goal: 1890000.0,
            variance: 5,
            adjustment_date: '2024-11-25T08:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          },
          history: {
            year_month: 202410,
            sent_goal: 1700000.0,
            adjusted_goal: 1785000.0,
            variance: 5,
            adjustment_date: '2024-10-25T08:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          }
        }
      ]
    },
    {
      type: 'Produção',
      year_month: 202411,
      goal: 20000000.0,
      adjustments: [
        {
          consultant_name: 'Ana Beatriz',
          role: 'Gerente Regional',
          consultant_id: '890123',
          reference: {
            year_month: 202411,
            sent_goal: 4000000.0,
            adjusted_goal: 4200000.0,
            variance: 5,
            adjustment_date: '2024-11-25T11:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          },
          history: {
            year_month: 202410,
            sent_goal: 3800000.0,
            adjusted_goal: 3990000.0,
            variance: 5,
            adjustment_date: '2024-10-27T11:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          }
        },
        {
          consultant_name: 'Cláudio Ribeiro',
          role: 'Consultor',
          consultant_id: '192837',
          reference: {
            year_month: 202411,
            sent_goal: 2000000.0,
            adjusted_goal: 2100000.0,
            variance: 5,
            adjustment_date: '2024-11-18T10:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          },
          history: {
            year_month: 202410,
            sent_goal: 1900000.0,
            adjusted_goal: 1995000.0,
            variance: 5,
            adjustment_date: '2024-10-19T10:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          }
        },
        {
          consultant_name: 'Marcela Souza',
          role: 'Consultora Comercial',
          consultant_id: '829384',
          reference: {
            year_month: 202411,
            sent_goal: 2200000.0,
            adjusted_goal: 2310000.0,
            variance: 5,
            adjustment_date: '2024-11-22T09:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          },
          history: {
            year_month: 202410,
            sent_goal: 2100000.0,
            adjusted_goal: 2205000.0,
            variance: 5,
            adjustment_date: '2024-10-22T09:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          }
        }
      ]
    },
    {
      type: '+Contas',
      year_month: 202411,
      goal: 15000000.0,
      adjustments: [
        {
          consultant_name: 'Roberto Faria',
          role: 'Consultor Master',
          consultant_id: '567890',
          reference: {
            year_month: 202411,
            sent_goal: 2500000.0,
            adjusted_goal: 2625000.0,
            variance: 5,
            adjustment_date: '2024-11-28T12:00:00.000Z',
            adjusted_by: 'Patrícia Lima'
          },
          history: {
            year_month: 202410,
            sent_goal: 2400000.0,
            adjusted_goal: 2520000.0,
            variance: 5,
            adjustment_date: '2024-10-30T12:00:00.000Z',
            adjusted_by: 'Patrícia Lima'
          }
        }
      ]
    },
    {
      type: 'Webmotors',
      year_month: 202411,
      goal: 18000000.0,
      adjustments: [
        {
          consultant_name: 'Gabriela Nunes',
          role: 'Coordenadora Comercial',
          consultant_id: '345678',
          reference: {
            year_month: 202411,
            sent_goal: 5000000.0,
            adjusted_goal: 5250000.0,
            variance: 5,
            adjustment_date: '2024-11-29T13:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          },
          history: {
            year_month: 202410,
            sent_goal: 4800000.0,
            adjusted_goal: 5040000.0,
            variance: 5,
            adjustment_date: '2024-10-31T13:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          }
        },
        {
          consultant_name: 'Ricardo Lima',
          role: 'Gerente de Área',
          consultant_id: '918273',
          reference: {
            year_month: 202411,
            sent_goal: 4000000.0,
            adjusted_goal: 4200000.0,
            variance: 5,
            adjustment_date: '2024-11-25T14:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          },
          history: {
            year_month: 202410,
            sent_goal: 3900000.0,
            adjusted_goal: 4095000.0,
            variance: 5,
            adjustment_date: '2024-10-25T14:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          }
        }
      ]
    }
  ]
}

export const mockedGoalsReportResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    runtime: 33,
    requestId: 'clujrt5x100jtyjty0b1djtkbl0zyfk'
  },
  data: mockGoalsReport,
  status: 200
}

export const mockedSavedGoalsAdjustmentsResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    runtime: 33,
    requestId: 'clujrt5x1sfdsf000b1djtkbl0zyfk'
  },
  data: {
    staffInfos: {
      name: 'Cristofer George',
      role: 'Gerente de Vendas'
    },
    goals: {
      production: {
        adjustedValue: 6527977.0,
        adjustedPercent: 13.97,
        value: 800000.0
      },
      insurance: {
        adjustedValue: 0,
        adjustedPercent: 0,
        value: 0
      },
      insurance_auto: {
        adjustedValue: 0,
        adjustedPercent: 0,
        value: 0
      },
      webmotors: {
        adjustedValue: 0,
        adjustedPercent: 0,
        value: 0
      },
      more_accounts: {
        adjustedValue: 100,
        adjustedPercent: -4,
        value: -4
      },
      charges: {
        adjustedValue: 0,
        adjustedPercent: 0,
        value: 0
      },
      active_base: {
        adjustedValue: 100,
        adjustedPercent: 5,
        value: 5
      }
    }
  },
  status: 200
}

export const getGoalsLeadersList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsLeadersListResponse))
  } else {
    await client.get<ResponseSuccess<TGoalsLeadersListItem[]>>(
      `v1/goals/leaders/${params.leader_std_code}`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          order_by: params.order_by ?? undefined,
          order_by_direction: params.order_by_direction ?? 'desc',
          ...params
        }
      }
    )
  }
}

export const getGoalsPeriod = async ({ period }: { period?: PeriodStatus }) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsPeriodResponse(period))) as any
  } else {
    return await client.get<ResponseSuccess<TGoalsPeriod>>(`v1/goals/period`)
  }
}

export const getGoalsStaffList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsStaffListResponse))
  } else {
    await client.get<ResponseSuccess<TGoalsStaffListItem[]>>(
      `v1/goals/leaders/${params.leader_std_code}/staff`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          order_by: params.order_by ?? undefined,
          order_by_direction: params.order_by_direction ?? 'desc',
          ...params
        }
      }
    )
  }
}

export const getGoalsLeaderDetails = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(
      mockAxiosResponse(mockedGoalsLeaderDetailsResponse({ goal_status: params?.goal_status }))
    ) as any
  } else {
    return await client.get<ResponseSuccess<TGoalsLeaderDetails>>(
      `v1/goals/leaders/${params.leader_std_code ?? 0}`,
      {}
    )
  }
}

export const openGoalsPeriod = async ({
  date_start,
  date_end,
  leader_std_code,
  goals
}: TOpenGoalsPeriodParams) => {
  return await client.post<ResponseSuccess<TOpenGoalsPeriodParams>>(`v1/goals/period`, {
    date_start,
    date_end,
    leader_std_code: leader_std_code ?? undefined,
    goals
  })
}

export const reactivateGoalsPeriod = async ({ leaderStdCode }: { leaderStdCode: number }) => {
  return await client.patch<ResponseSuccess<{ leaderStdCode: number }>>(
    `v1/goals/leaders/reactivate`,
    {
      leaderStdCode
    }
  )
}

export const getGoalsReport = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsReportResponse))
  } else {
    return await client.get<ResponseSuccess<TGoalsReport>>(
      `v1/goals/reports/${params.leader_std_code}`,
      {}
    )
  }
}

export const concludeGoalsManagement = async ({ leader_std_code }: TParams) =>
  await client.post<ResponseSuccess<TParams>>(`v1/goals/complete`, {
    leader_std_code
  })

export const saveGoalsAdjustments = async ({ staff_std_code, goals_adjustments }: TParams) =>
  await client.post<ResponseSuccess<TParams>>(`v1/goals/save`, {
    staff_std_code,
    goals_adjustments
  })

export const getSavedGoalsAdjustments = async (staff_std_code: number) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedSavedGoalsAdjustmentsResponse)) as any
  } else {
    return await client.get<ResponseSuccess<TGoalAdjustmentsItem>>(
      `v1/goals/save/${staff_std_code}`,
      {}
    )
  }
}
